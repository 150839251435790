import React from 'react'
import {Link} from 'gatsby'
import atomIcon from '../../assets/images/Yummy-Technologies-icon-little.png'
import serviceIcon1 from '../../assets/images/services/service-web.png'
import serviceIcon2 from '../../assets/images/services/service-mobile-app.png'
import serviceIcon3 from '../../assets/images/services/service-seo.png'

const FeaturedServices = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={atomIcon} width="5%" alt="features" /> 
                         Services
                    </span>
                    <h2>Nos services</h2>
                    <p>Nous travaillons avec les dernières technologies afin de toujours fournir un service de qualité.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} width="30%" alt="création de site web" />
                            </div>
                            <h3>
                                <Link to="/service-web">
                                    Création de site web
                                </Link>
                            </h3>
                            <p>Votre site web est la vitrine de votre entreprise, il est important d'en considérer son importance. Nous développerons votre site web ou webshop sur mesure.</p>

                            <Link to="/service-web" className="read-more-btn">
                                En savoir plus
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} width="30%" alt="Développement d'application" />
                            </div>
                            <h3>
                                <Link to="/service-mobile">
                                    Développement d'application 
                                </Link>
                            </h3>
                            <p>Passez au numérique en offrant à vos employés ou clients une expérience supérieure avec une application mobile Android et iOS.</p>

                            <Link to="/service-mobile" className="read-more-btn">
                                En savoir plus
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} width="30%" alt="SEO référencement google" />
                            </div>

                            <h3>
                                <Link to="/service-seo">
                                    Marketing & SEO
                                </Link>
                            </h3>
 
                            <p>Envie d'améliorer votre présence en ligne ? Nous analysons votre site et l'optimisons afin d'améliorer son référencement sur Google. Touchez plus de clients!</p>

                            <Link to="/service-seo" className="read-more-btn">
                                En savoir plus 
                                <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedServices